/* Declare variables to be used in LESS files (react-big-calendar, react-widgets...) */
.batch-booking-banner {
  background: #eeeeff;
  min-height: 40px;
  padding: 0 10px;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.batch-booking-banner .spinner-border {
  margin-top: 10px;
  margin-right: 10px;
}
.batch-booking-banner .btn {
  margin-top: 5px;
}
.batch-booking-banner p {
  margin-top: 10px;
}
