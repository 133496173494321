/* Override Bootstrap variables */
.calendar-session-color label {
  display: none; }

.calendar-session-color .form-group {
  margin: 0; }

.calendar-session-color .mss-color-picker-pill {
  width: 30px;
  height: 30px; }
