/* Override Bootstrap variables */
.image-wrapper {
  position: relative;
  width: 200px;
  margin: 0 15px 15px 0; }
  .image-wrapper .download-button {
    position: absolute;
    bottom: -6px;
    left: -6px; }
    .image-wrapper .download-button .btn-secondary {
      border-radius: 65px !important;
      border-color: white; }
  .image-wrapper .btn.btn-danger {
    position: absolute;
    bottom: -6px;
    right: -6px;
    border-radius: 50px;
    border-color: white; }

.file-input-invalid-feedback {
  display: block !important; }
