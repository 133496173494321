/* Override Bootstrap variables */
.mss-btn-add {
  position: fixed;
  right: 5.5em;
  bottom: 1.5em;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  line-height: 50px;
  cursor: pointer;
  background-color: #ed2c1a;
  color: #ffffff;
  z-index: 20; }
  .mss-btn-add:hover {
    box-shadow: 0 0 0 0.2rem rgba(22, 13, 79, 0.25); }
